@import url("https://fonts.googleapis.com/css2?family=Nandos:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Nandos", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Link */
a:-webkit-any-link {
  text-decoration: none;
}

.backdropTitle {
  transform: rotate(-2deg);
  margin-bottom: 3rem;
  width: fit-content;
}

.innerPageLayout {
  padding: 42px 21px;
}

.section {
  min-height: 120px;
}

.hide_secion {
  display: none;
}

.sub-section {
  padding: 16px 0px;
}

.flag-icon {
  width: 30px;
}

/* Login Form */
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.logo-text {
  font-size: 70px;
  font-weight: bold;
}

.small-logo-text {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.heading-text {
  font-size: 30px;
  font-weight: bold;
}

.link-text {
  font-weight: bold;
  color: black;
  text-decoration: none;
  padding-left: 20px;
}

.img-pad {
  padding-left: 20px;
  padding-top: 20px;
}

.gry {
  background-color: #222222;
}

.nandosfont {
  font-family: "Nandos";
}

/* @font-face {
  font-family: "Nandos";
  src: local("Nandos"), url(./fonts/Nandos-Regular.ttf) format("truetype");
} */
@font-face {
  font-family: "Nandos";
  src: url("./fonts/Nandos-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
